@import 'layout';
@import "variables";

tabset {
    .nav-item {
        @extend .border-radius;
        @extend .bg-primary;
        font-weight: bold;
        
        a {
            color: white !important;
        }

        &.active {
            @extend .border;
            border-bottom: none !important;

            a {
                color: $color-primary !important;
            }
        }
    }
}
