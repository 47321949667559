@import "variables";
@import "text";

.btn {
    height: 2.5rem !important;
    border-radius: 0.3rem !important;
    font-size: 0.9rem !important;
    font-weight: bold;
}

.btn-icon {
    padding: 0.25rem 0.5rem !important;
}

.btn-primary {
    color: white;
    background-color: $color-primary !important;
    border-color: $color-primary !important;
    border-style: solid;

    &:hover {
        background-color: $color-secondary !important;
        border-color: $color-secondary !important;
    }
}

.btn-primary-alt {
    color: $color-primary;
    background-color: white !important;
    border-color: $color-primary !important;
    border-style: solid;

    &:hover {
        color: white;
        background-color: $color-primary !important;
        border-color: $color-primary !important;
    }
}

.btn-secondary {
    color: white;
    background-color: $color-secondary !important;
    border-color: $color-secondary !important;
    border-style: solid;
}

.btn-white {
    color: black;
    background-color: white !important;
    border-color: white !important;

    &:hover {
        color: white;
        background-color: $color-tertiary !important;
        border-color: $color-tertiary !important;
    }
}


