.content-wrapper.kanban {
  height: 1px;

  .content {
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    .container,
    .container-fluid {
      width: max-content;
      display: flex;
      align-items: stretch;
      overflow: hidden !important;
    }
  }
  .content-header + .content {
    height: calc(100% - ((2 * 15px) + (1.8rem * #{$headings-line-height})));
  }

  .card {
    .card-body {
      padding: .5rem;
    }

    &.card-row {
      width: 340px;
      display: inline-block;
      margin: 0 .5rem;

      &:first-child {
        margin-left: 0;
      }

      .card-body {
        height: calc(100% - (12px + (1.8rem * #{$headings-line-height}) + .5rem));
        overflow-y: auto;
      }

      .card {
        &:last-child {
          margin-bottom: 0;
          border-bottom-width: 1px;
        }
        .card-header {
          padding: .5rem .75rem;
        }
        .card-body {
          padding: .75rem;
        }
      }
    }
  }
  .btn-tool {
    &.btn-link {
      text-decoration: underline;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
