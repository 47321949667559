@import "variables";

.cursor-pointer {
    cursor: pointer;
}

.divider {
    display: block;
    border: 1px solid $color-tertiary;

    &-light {
        border-color: lighten($color-tertiary, 50%);
    }
}
