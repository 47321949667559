@import '~@ng-select/ng-select/themes/default.theme.css';
@import 'scss/partials';

html,
body {
    height: 100%;
    background-color: $color-background;
}

ol,
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

@media (min-width: 576px) {
    .nav-user-menu .user-image {
        float: none;
        line-height: 10px;
        margin-top: -8px;
    }
}

.nav-user-menu .user-image {
    border-radius: 50%;
    float: left;
    height: 2rem;
    margin-top: -4px;
    width: 2rem;
}

.form-group.required .col-form-label:after {
    color: #d00;
    content: '*';
    position: absolute;
    margin-left: 3px;
}


/* ng-select */
.ng-select .ng-select-container {
    min-height: 38px !important;
}

.ng-select.ng-select-single .ng-select-container {
    height: 31px !important;
}

.input-group-append .ng-select .ng-select-container {
    border-radius: 0 4px 4px 0 !important;
}

ng-select.form-control {
    padding: initial !important;
}

.form-control .ng-select-container {
    position: initial !important;
    border: 0px;
    background: transparent !important;
    min-height: initial !important;
    height: inherit !important;
}

.form-control ng-value-container {
    padding-top: 0px !important;
}

bs-tooltip-container {
    transition-duration: 0ms;
}

.freeze-ui {
    position: fixed !important;
}

.color-black {
    color: black !important;
}

.text-end {
    text-align: end !important;
}
