@import 'variables';

html,
body {
    font-family: $font-family;
}

//Colors
.text-primary {
    color: $color-primary !important;
}

a.text-primary:hover:not(.dropdown-item),
a.text-primary:focus:not(.dropdown-item) {
    color: $color-secondary !important;
}

.text-secondary {
    color: $color-secondary !important;
}

.text-tertiary {
    color: $color-tertiary !important;
}

.text-black {
    color: black !important;
}

//Size
h1 {
    font-size: 1.5rem !important;
    font-weight: bold;
}

h2 {
    font-size: 1rem !important;
    font-weight: bold;
}

h3 {
    font-size: 0.9rem !important;
    font-weight: bold;
}

p {
    font-size: 0.9rem !important;
    font-weight: normal;
}
