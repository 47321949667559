@import "layout";
@import "buttons";
@import "variables";

.fc-toolbar-title {
    display: inline !important;
}

.fc-header-toolbar {
    @extend .bg-primary;

    .fc-toolbar-chunk {
        border-radius: 0.3rem !important;
        background-color: white;

        button {
            @extend .btn-white
        }
    }
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: $color-background;
}

.fc-event-title {
    white-space: normal;
}
