table.table {
    * {
        font-size: 1rem !important;
    }

    th,
    td {
        padding: 1rem;
        vertical-align: middle;
    }
}

.table {
    th,
    td {
        border-top: 1px solid $color-tertiary !important;
        border-bottom: 1px solid $color-tertiary !important;
        border-collapse: collapse !important;
    }
}

table-card-footer * {
    font-size: 14px;
}
