@import "variables";

/* Sweet alert */
.swal2-title {
    display: inline-block !important;
}

body.swal2-height-auto {
    height: inherit !important;
}

.swal2-popup.swal2-toast {
    padding: 10px 12px !important;
    display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
    text-align: left !important;
    margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
    margin-bottom: 4px !important;
}

button.swal2-confirm.swal2-styled {
    background-color: $color-primary !important;

    &:focus {
        box-shadow: 0 0 0 3px $color-primary !important;
    }
}
