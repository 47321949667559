@import "variables";

#container {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto -50px;
}

//Backgrounds
.bg-primary {
    background: $color-primary !important;

    &-light {
        background: lighten($color-primary, 50%) !important;
    }
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
    background: $color-secondary !important;
}

.bg-secondary {
    background: $color-secondary !important;
}

//Borders
.border {
    border: 1px solid black !important;
}

.border-primary {
    border-color: $color-primary !important;
}


.border-tertiary {
    border-color: $color-tertiary !important;
}

.border-transparent {
    border-color: transparent !important;
}

.border-radius {
    border-radius: 0.25rem !important;
}

.border-radius-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.border-radius-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

//Commons
.section-header {
    font-size: 1.25rem !important;
    font-weight: bold;
}

//Navbar
.nav-item.active {
    border-bottom: 3px solid $color-primary !important;
}

.nav-link {
    color: $color-primary !important;
}

//Footer
.swal2-footer {
    background: $color-primary;
    color: #ffff;
    justify-content: center;
    align-items: center;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    border-top: 1px solid #ccc;
    z-index: 9999;
    margin-top: 64px;
    margin-bottom: 0;
}

.footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.address-text {
    color: #ffff;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 32px;
}

#footer, #push {
    height: 50px;
    clear: both;
}

//Sidebar
div.wrapper,
div.content-wrapper,
div.sidebar {
    min-height: 100vh;
}


