@import "variables";

//Pagination
.page-item {
    margin-left: 1rem;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: $color-primary;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid transparent !important;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
    color:$color-primary !important;
    border: 1px solid $color-primary !important;
}

.page-item .page-link {
    color: $color-primary;
}

.page-item .page-link:hover,
.page-item.active .page-link {
    background-color: $color-primary !important;
    color: white !important;
}

.pagination {
    justify-content: center
}
